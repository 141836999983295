import React from 'react';

import styles from './AboveTheFoldSection.module.css';

import Title from '../../../elements/Title';
import Subtitle from '../../../elements/Subtitle';
import Container from '../../../blocks/Container';
import FullWidthRow from '../../../blocks/FullWidthRow';
import LeftGirl from '../../../../images/get-started/girl-left.svg';
import RightBoy from '../../../../images/get-started/boy-right.svg';
import MobileGirl from '../../../../images/get-started/girl-mobile.svg';

const AboveTheFoldSection = ({ctaUrl = "https://start.deadlinefunnel.com/create-monthly", funnel=false}) => (
  <div className={styles.aboveTheFoldSection}>
    <Container>
      <Container type="mobile">
        <FullWidthRow className={styles.mobileTextLeft}>
          <Title type="huge" className="mb-3">
            Join 3,700+ Creators
            <br className="desktop-and-tablet-br" /><span className={styles.aboveTheFoldSection__redUnderline}> Converting More Leads</span>
            <br  className="desktop-and-tablet-br"/> Into Customers
          </Title>
          <Subtitle type="big" className={styles.subTitle}>
            Try it free for 14 days with NO credit card required!
          </Subtitle>          
          <div className={`${styles.aboveTheFoldSection__linksContainer} text-center`}>
            <a className={styles.aboveTheFoldSection__link} href={ctaUrl}>
              Start Your Free Trial Now!
            </a>
          </div>
        </FullWidthRow>
        <MobileGirl className={styles.aboveTheFoldSection__girlMobile} />
        <LeftGirl className={styles.aboveTheFoldSection__girl} />
        <RightBoy className={styles.aboveTheFoldSection__boy} />
      </Container>
    </Container>
  </div>
);

export default AboveTheFoldSection;