import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import AboveTheFoldSection from '../components/slides/get-started/AboveTheFoldSectionNoCC';
import BeforeVsAfter from '../components/slides/home/BeforeVsAfter';
import TrustedBy from '../components/slides/home/TrustedBy';
import WhatYouCanBuild from '../components/slides/home/WhatYouCanBuild';
import CourseCreator from '../components/slides/home/CourseCreator';
import Features from '../components/slides/home/Features';
import HelpfulTools from '../components/slides/home/HelpfulTools';
import BlogPosts from '../components/slides/home/BlogPosts';
import FeaturedIn from '../components/slides/get-started/FeaturedIn';
import FeaturedServices from '../components/slides/get-started/FeaturedServices';

const ctaUrl = "/signup";

const HomePageNoCC = () => (
  <Layout ctaUrl={ctaUrl}>
    <SEO title="Authentic Evergreen Marketing | Deadline Funnel" meta={[{
          name: 'robots',
          content: 'noindex',
        }]} />
    <AboveTheFoldSection ctaUrl={ctaUrl} funnel="no-cc" />
    <FeaturedIn />
    <FeaturedServices />
    <BeforeVsAfter />
    <TrustedBy />
    <WhatYouCanBuild />
    <CourseCreator ctaUrl={ctaUrl} />
    <Features ctaUrl={ctaUrl} />
    <HelpfulTools />
    <BlogPosts />
  </Layout>
);

export default HomePageNoCC;
